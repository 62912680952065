import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as api from "api/admin/template";
import { EmailTemplate } from "api/types";
import { UUID } from "crypto";
import { useNavigate } from "react-router-dom";
import { useLoading } from "components/LoadingProvider";

const EmailTemplatesPage = () => {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplate | undefined
  >(undefined);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchEmailTemplates = async () => {
    setIsLoading(true);
    const res = await api.getEmailTemplates();
    if (res.status === 200) {
      setEmailTemplates(res.data);
    }
    setIsLoading(false);
  };

  const fetchEmailTemplate = async (emailTemplateUuid: UUID) => {
    setIsLoading(true);
    const res = await api.getEmailTemplate(emailTemplateUuid);
    if (res.status === 200) {
      setSelectedEmailTemplate(res.data);
    }
    setIsLoading(false);
  };

  const deleteEmailTemplate = async (emailTemplateUuid: UUID) => {
    setIsLoading(true);
    const res = await api.deleteEmailTemplate(emailTemplateUuid);
    if (res.status === 200) {
      setSelectedEmailTemplate(undefined);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // fetch list
    fetchEmailTemplates();
  }, []);

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/email-templates/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (params: any) => {
    const handleOnClick = async (e: any) => {
      await deleteEmailTemplate(params.id);
      await fetchEmailTemplates();
    };

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Delete
      </Button>
    );
  };

  const PreviewButton = (params: any) => {
    const emailTemplateUuid = params.id;
    const handleOnRowClick = async () => {
      fetchEmailTemplate(emailTemplateUuid as UUID);
    };

    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnRowClick}
      >
        Preview
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "emailTemplateId", headerName: "ID", width: 70 },
    { field: "emailSubject", headerName: "Subject", width: 200 },
    { field: "emailSenderName", headerName: "Sender", width: 150 },
    { field: "senderEmail", headerName: "From", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
    {
      field: "Delete",
      headerName: "",
      width: 150,
      renderCell: DeleteButton,
    },
    {
      field: "Preview",
      headerName: "",
      width: 150,
      renderCell: PreviewButton,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Email Template List
          </Typography>
        </Box>
        <Grid item xs={selectedEmailTemplate ? 8 : 12}>
          <DataGrid
            rows={emailTemplates}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50]}
            getRowId={(row) => row.emailTemplateUuid}
          />
        </Grid>
        {selectedEmailTemplate && (
          <Grid item xs={4}>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedEmailTemplate.emailBody,
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EmailTemplatesPage;
