import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Save, Delete } from "@mui/icons-material";
import { useLoading } from "components/LoadingProvider";
import { Phishee } from "api/types";
import * as api from "api/admin/phishee";
import { UUID } from "crypto";
import { JOB_FUNCTIONS } from "const";

const PhisheeDetail = () => {
  const { phisheeUuid } = useParams();
  const [phishee, setPhishee] = useState<Phishee | undefined>(undefined);
  const { setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const fetchPhishee = async () => {
    if (!phisheeUuid) {
      return;
    }
    setIsLoading(true);

    const res = await api.getPhishee(phisheeUuid);
    if (res.status === 200) {
      setPhishee(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchParams.get("account")) {
      setPhishee({
        account: {
          accountUuid: searchParams.get("account") as UUID,
        },
        isActive: undefined,
      });
    }

    fetchPhishee();
  }, []);

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!phishee) {
      console.log("No account", phishee);
      return;
    }

    const payload = {
      accountUuid: phishee?.account?.accountUuid,
      email: phishee.user?.email,
      firstName: phishee.user?.firstName,
      lastName: phishee.user?.lastName,
      jobFunction: phishee.jobFunction,
      isActive: phishee.isActive,
    };

    let response = null;
    if (phisheeUuid) {
      response = await api.putPhishee(phisheeUuid, payload);
    } else {
      response = await api.postPhishee(payload);
    }

    if (response?.status === 200) {
      return navigate(0);
    }
    if (response?.status == 201) {
      return navigate(`/admin/phishees/${response.data.phisheeUuid}`);
    }
  };

  const handleDelete = async (_event: React.FormEvent<HTMLButtonElement>) => {
    alert("Delete not supported yet.");
  };

  if (!phishee && phisheeUuid) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Phishee Detail
            </Typography>
          </Box>
          <Box>
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={phishee?.account?.accountUuid}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  account: {
                    ...phishee?.account,
                    accountUuid: e.target.value as UUID,
                  },
                })
              }
              disabled
            />
            <TextField
              margin="normal"
              label="Email"
              variant="outlined"
              fullWidth
              value={phishee?.user?.email}
              disabled={!!phisheeUuid}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, email: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="First Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.firstName}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, firstName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Last Name"
              variant="outlined"
              fullWidth
              value={phishee?.user?.lastName}
              onChange={(e) =>
                setPhishee({
                  ...phishee,
                  user: { ...phishee?.user, lastName: e.target.value },
                })
              }
            />
            <TextField
              margin="normal"
              label="Created Date"
              variant="outlined"
              fullWidth
              value={phishee?.createdDate}
              disabled
            />
            <Box>
              <FormControlLabel
                value="jobFunction"
                control={
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phishee?.jobFunction}
                    label="Age"
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        jobFunction: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={undefined}>Select</MenuItem>
                    {JOB_FUNCTIONS.map((jobFunction) => (
                      <MenuItem key={jobFunction} value={jobFunction}>
                        {jobFunction}
                      </MenuItem>
                    ))}
                  </Select>
                }
                label="Job Function"
                labelPlacement="start"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="isActive"
                control={
                  <Checkbox
                    checked={phishee?.isActive}
                    onChange={(e) =>
                      setPhishee({
                        ...phishee,
                        isActive: e.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                labelPlacement="start"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSave} startIcon={<Save />}>
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{ ml: 3 }}
            startIcon={<Delete />}
          >
            Delete
          </Button>
          {phishee?.account?.accountUuid && (
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/admin/accounts/${phishee?.account?.accountUuid}`)
              }
              sx={{ ml: 3 }}
              startIcon={<Delete />}
            >
              Open Account
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PhisheeDetail;
