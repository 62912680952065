import ReactDOM, { Container } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "axiosConfig";

import Layout from "pages/phishee/Layout";
import Home from "pages/landing/Home";
import Login from "pages/Login";
import Dashboard from "pages/phishee/Dashboard";
import SignUp from "pages/SignUp";
import AdminLogin from "pages/admin/AdminLogin";
import AdminDashboard from "pages/admin/AdminDashboard";
import AdminLayout from "pages/admin/AdminLayout";
import Tier1TemplateGenerator from "pages/admin/Tier1TemplateGenerator";
import Tier2TemplateGenerator from "pages/admin/Tier2TemplateGenerator";
import EmailTemplatesPage from "pages/admin/EmailTemplatesPage";
import EmailTemplateDetailPage from "pages/admin/EmailTemplateDetail";
import { LoadingProvider } from "components/LoadingProvider";
import AccountsPage from "pages/admin/AccountsPage";
import AccountDetailPage from "pages/admin/AccountDetailPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fontFamily } from "style";
import PhisheeList from "pages/admin/PhisheeList";
import PhisheeDetail from "pages/admin/PhisheeDetail";

const theme = createTheme({
  typography: {
    fontFamily: fontFamily.primary,
  },
});

export default function App() {
  return (
    <CookiesProvider>
      <LoadingProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
              </Route>
              <Route path="/login" element={<Layout />}>
                <Route index element={<Login />} />
              </Route>
              <Route path="/dashboard" element={<Layout />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="/signup" element={<Layout />}>
                <Route index element={<SignUp />} />
              </Route>
              <Route path="/admin" element={<Layout />}>
                <Route index element={<AdminLogin />} />
              </Route>
              {/* Admin  */}
              <Route path="/admin/dashboard" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
              </Route>
              <Route path="/admin/accounts" element={<AdminLayout />}>
                <Route index element={<AccountsPage />} />
              </Route>
              <Route path="/admin/account/new" element={<AdminLayout />}>
                <Route index element={<AccountDetailPage />} />
              </Route>
              <Route
                path="/admin/accounts/:accountUuid"
                element={<AdminLayout />}
              >
                <Route index element={<AccountDetailPage />} />
              </Route>
              <Route path="/admin/phishees" element={<AdminLayout />}>
                <Route index element={<PhisheeList />} />
              </Route>
              <Route path="/admin/phishees/new" element={<AdminLayout />}>
                <Route index element={<PhisheeDetail />} />
              </Route>
              <Route
                path="/admin/phishees/:phisheeUuid"
                element={<AdminLayout />}
              >
                <Route index element={<PhisheeDetail />} />
              </Route>

              <Route
                path="/admin/tier-1-template-generator"
                element={<AdminLayout />}
              >
                <Route index element={<Tier1TemplateGenerator />} />
              </Route>
              <Route
                path="/admin/tier-2-template-generator"
                element={<AdminLayout />}
              >
                <Route index element={<Tier2TemplateGenerator />} />
              </Route>
              <Route path="/admin/email-templates" element={<AdminLayout />}>
                <Route index element={<EmailTemplatesPage />} />
              </Route>
              <Route
                path="/admin/email-templates/:emailTemplateUuid"
                element={<AdminLayout />}
              >
                <Route index element={<EmailTemplateDetailPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </LoadingProvider>
    </CookiesProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as Container);
root.render(<App />);
