import axios, { AxiosResponse } from "axios";
import { ValidationError, Phishee, PhisheeListFilter } from "api/types";

export const getPhishees = async (
  filter?: PhisheeListFilter
): Promise<AxiosResponse<Phishee[]>> => {
  const url = `/api/v1/phishee`;
  let res = null;
  try {
    res = await axios.get(url, {
      params: filter,
    });

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const getPhishee = async (
  phisheeUuid: string
): Promise<AxiosResponse<Phishee>> => {
  const url = `/api/v1/phishee/${phisheeUuid}`;
  let res = null;
  try {
    const payload = {};
    res = await axios.get(url, payload);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export interface PhisheePayload {
  accountUuid: string;
  email: string;
  firstName: string;
  lastName: string;
  jobFunction: string;
  isActive: boolean;
}
export const postPhishee = async (payload: Phishee) => {
  const url = `/api/v1/phishee`;
  let res = null;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.post(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};

export const putPhishee = async (phisheeUuid: string, payload: Phishee) => {
  const url = `/api/v1/phishee/${phisheeUuid}`;
  let res = null;
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    res = await axios.put(url, payload, config);

    return res;
  } catch (error) {
    if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
      console.log(error.status);
      console.error(error.response);
      // Do something with this error...
    } else {
      console.error(error);
    }
    throw error;
  }
};
