import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import { Container } from "@mui/system";
import { Collapse } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import { Cookies } from "react-cookie";
import { onLogout } from "api/auth";
import { ContactMail } from "@mui/icons-material";

const Menu = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    slug: "/admin/dashboard",
  },
  {
    name: "Email Templates",
    icon: <EmailIcon />,
    slug: "/admin/email-templates",
  },
  {
    name: "Template Generator",
    icon: <MoveToInboxIcon />,
    slug: "/admin/template-generator",
  },
];

const AdminLayout = () => {
  const cookies = new Cookies();
  const adminAccessToken = cookies.get("adminAccessToken");

  if (!adminAccessToken) {
    window.location.href = "/admin";
  }

  const handleLogout = () => {
    onLogout();
    cookies.remove("adminAccessToken");
    window.location.href = "/admin";
  };

  const drawerWidth = 250;

  return (
    <>
      <Container
        component="main"
        sx={{ p: { xs: 0 }, maxWidth: { md: "none" } }}
        id="upphish-container"
      >
        <Box sx={{ display: "flex" }}>
          <Drawer
            anchor="left"
            // open={true}
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            // ModalProps={{
            //   keepMounted: true,
            // }}
          >
            <Box role="presentation">
              <List>
                <ListItem key="Dashboard" disablePadding>
                  <ListItemButton component={Link} to={"/admin/dashboard"}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Accounts" disablePadding>
                  <ListItemButton component={Link} to={"/admin/accounts"}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Phishees" disablePadding>
                  <ListItemButton component={Link} to={"/admin/phishees"}>
                    <ListItemIcon>
                      <ContactMail />
                    </ListItemIcon>
                    <ListItemText primary="Phishees" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Email Templates" disablePadding>
                  <ListItemButton
                    component={Link}
                    to={"/admin/email-templates"}
                  >
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email Templates" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Template Generator" disablePadding>
                  <ListItemButton component="div">
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Template Generator" />
                  </ListItemButton>
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/admin/tier-1-template-generator"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter1Icon />
                      </ListItemIcon>
                      <ListItemText primary="Tier 1" />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/admin/tier-2-template-generator"
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon>
                        <Filter2Icon />
                      </ListItemIcon>
                      <ListItemText primary="Tier 2" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItem key="logout" disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              overflow: "auto",
              width: "calc(100% - 250px)",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AdminLayout;
