export const PREMIUM_PLAN = "Premium";
export const STANDARD_PLAN = "Standard";
export const FREE_PLAN = "Free";
export const ACCOUNT_PLANS = [PREMIUM_PLAN, STANDARD_PLAN, FREE_PLAN];

export const JOB_FUNCTIONS = [
  "Accounting / Finance",
  "Administrative",
  "Arts / Media / Design",
  "Biotech / Science",
  "Business / Management",
  "Customer Service",
  "Education",
  "Engineering",
  "Government",
  "Healthcare",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing / Advertising / PR",
  "Non-Profit / Volunteer",
  "Real Estate",
  "Restaurant / Food Service",
  "Retail",
  "Sales",
  "Skilled Trade / Craft",
  "Transportation",
  "Other",
];
