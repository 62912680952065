import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { Save, Delete, AddBox } from "@mui/icons-material";
import { useLoading } from "components/LoadingProvider";
import { Account } from "api/types";
import { getAccount, putAccount, deleteAccount } from "api/admin/accounts";
import { PREMIUM_PLAN, STANDARD_PLAN, FREE_PLAN } from "const";

const AccountDetailPage = () => {
  const { accountUuid } = useParams();
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const fetchAccount = async () => {
    if (!accountUuid) {
      return;
    }
    setIsLoading(true);

    const res = await getAccount(accountUuid);
    if (res.status === 200) {
      setAccount(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  const handleSave = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!account) {
      console.log("No account", account);
      return;
    }

    const payload = {
      accountName: account.accountName,
      plan: account.plan,
      isActive: account.isActive,
    };
    const response = await putAccount(account.accountUuid, payload);
    if (response.status === 200) {
      console.log("Saved successfully");
      navigate(0);
    }
  };

  const handleDelete = async (_event: React.FormEvent<HTMLButtonElement>) => {
    if (!account) {
      console.log("No account", account);
      return;
    }

    const response = await deleteAccount(account.accountUuid);
    if (response.status === 204) {
      console.log("deleted successfully");
      return navigate("/admin/accounts");
    }
  };

  const handleAddPhishee = async (
    _event: React.FormEvent<HTMLButtonElement>
  ) => {
    return navigate(`/admin/phishees/new?account=${account?.accountUuid}`);
  };

  if (!account) {
    return <div>Loading...</div>;
  }

  const DetailButton = (params: any) => {
    const handleOnClick = (e: any) => {
      return navigate(`/admin/phishees/${params.id}`);
    };

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleOnClick}
      >
        Edit
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "phisheeId", headerName: "ID", width: 70 },
    {
      field: "Name",
      headerName: "Name",
      width: 200,
      valueGetter: (_params, row) =>
        row?.user?.firstName + " " + row?.user?.lastName,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      valueGetter: (_params, row) => row.user?.email,
    },
    { field: "createdDate", headerName: "Created", width: 150 },
    {
      field: "isActive",
      type: "boolean",
      headerName: "Active",
      width: 150,
      valueGetter: (_value, row) => row?.isActive,
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
      valueGetter: (_value, row) => row.accountName,
    },
    {
      field: "Edit",
      headerName: "",
      width: 150,
      renderCell: DetailButton,
    },
  ];

  return (
    <>
      <Grid spacing={2} id="account-detail">
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5" gutterBottom>
              Account Detail
            </Typography>
          </Box>
          <Box>
            <TextField
              margin="normal"
              label="Account Name"
              variant="outlined"
              fullWidth
              value={account.accountName}
              onChange={(e) =>
                setAccount({
                  ...account,
                  accountName: e.target.value,
                })
              }
            />
            <TextField
              margin="normal"
              label="Created Date"
              variant="outlined"
              fullWidth
              value={account.createdDate}
              disabled
            />
            <Box>
              <FormControlLabel
                value="isActive"
                control={
                  <Checkbox
                    checked={account.isActive}
                    onChange={(e) =>
                      setAccount({
                        ...account,
                        isActive: e.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                labelPlacement="start"
              />
            </Box>
            <Box>
              <FormControlLabel
                value="plan"
                control={
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={account.plan}
                    label="Age"
                    sx={{ ml: 2, p: 1 }}
                    onChange={(e) =>
                      setAccount({
                        ...account,
                        plan: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={undefined}></MenuItem>
                    <MenuItem value={FREE_PLAN}>{FREE_PLAN}</MenuItem>
                    <MenuItem value={STANDARD_PLAN}>{STANDARD_PLAN}</MenuItem>
                    <MenuItem value={PREMIUM_PLAN}>{PREMIUM_PLAN}</MenuItem>
                  </Select>
                }
                label="Plan"
                labelPlacement="start"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ py: 3 }}>
          <Button variant="contained" onClick={handleSave} startIcon={<Save />}>
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{ ml: 3 }}
            startIcon={<Delete />}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={handleAddPhishee}
            sx={{ ml: 3 }}
            startIcon={<AddBox />}
          >
            Add Phishee
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box style={{ paddingBottom: "1rem" }}>
            <DataGrid
              rows={account.phishees}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50]}
              getRowId={(row) => row.phisheeUuid}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetailPage;
